import React, { ReactElement } from 'react'

import Template from '@/components/app/Template'
import { getPage } from '@/themes'
import { SearchPageProps } from '@/types/pages/search'

/**
 * Home page
 */
export default function Home(props: SearchPageProps): ReactElement {
  const SearchPage = getPage<SearchPageProps>(`Search`)
  return (
    <Template {...props}>
      <SearchPage {...props} />
    </Template>
  )
}
